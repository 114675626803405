import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate ,useParams} from 'react-router-dom';
import FixedBackground from '../Images/cimg4.webp';

import YPOM from "../Image/Your Pace or Mine.JPG";
import WarofWits from "../Image/Warofwits.jpg";
import Volleyball from "../Image/Volleyball .PNG";
import Unstory from "../Image/Unstory.png";
import Treasurehunt from "../Image/Treasure hunt.webp";
import Tennis from "../Image/Tennis.PNG";
import TattooTales from "../Image/Tattoo tales.png";
import Tarang from "../Image/Tarang.jpg";
import Tambola from "../Image/Tam ola.webp";
import TableTennis from "../Image/Table Tennis .PNG";

import StreetBlaze from "../Image/Street Blaze.jpeg";
import Splashtees from "../Image/Splash tees.png";
import SoulSync from "../Image/Soul Sync.JPG";
import Songstravaganza from "../Image/Songstravaganza.webp";
// import Rythm revolution from "../Image/Rythm revolution.jpeg";
import RipnStitch from "../Image/Rip n Stitch.png";

import Prose from "../Image/Prose the pictures.png";
import Pictionary from "../Image/Pictionary.webp";
import Paperdance from "../Image/Paper dance.webp";
import NukkadNatak from "../Image/Nukkad Natak.webp";
import Nrityasangam from "../Image/Nritya sangam.JPG";
import MusicalChairs from "../Image/Musical Chairs.webp";
import Metallica from "../Image/Metallica_.jpg";
import mehendimania from "../Image/mehendi mania.png";
import MaddAngle from "../Image/Madd Angle.JPG";
import Kavyotsav from "../Image/Kavyotsav.png";
import Kabbadi from "../Image/Kabbadi.PNG";
import JAM from "../Image/JAM.png";

// import IMG_20240915_161502_033 from "../Image/IMG_20240915_161502_033.jpg";
// import IMG_20240915_161500_466 from "../Image/IMG_20240915_161500_466.jpg";
import IconicImpressions from "../Image/Iconic Impressions.png";
import Futsal from "../Image/Futsal.webp";
import Football from "../Image/Football.jpg";
import Fantasyfaces from "../Image/Fantasy faces.png";
import EveningAmore from "../Image/Evening Amore.webp";
import Euphonia from "../Image/Euphonia.jpeg";
import Echoes from "../Image/Echoes and Expressions.webp";
import Drapeit from "../Image/Drape it.JPG";
import DartySecrets from "../Image/Darty Secrets.JPG";
import Cupid from "../Image/Cupid_s Countdown.jpg";
import Cupful from "../Image/Cupful of doodles.png";
import Cricket from "../Image/Cricket.PNG";
import ContrastChronicles from "../Image/Contrast chronicles.png";
import ComicCombat from "../Image/Comic Combat.webp";
import Cognizzia from "../Image/Cognizzia.png";
import Cineholics from "../Image/Cineholics.jpg";
import Chronos from "../Image/Chronos.JPG";
import Chess from "../Image/Chess.JPG";
import Carrom from "../Image/Carrom.webp";
import Capture from "../Image/Capture and conquer.webp";
import Caffeinecreations from "../Image/Caffeine creations.png";
import Brushlessstrokes from "../Image/Brushless strokes.png";
import BlossomingSteps from "../Image/Blossoming Steps.jpeg";
import BiocruxSr from "../Image/Biocrux Sr..jpg";
// import biocruxJr from "../Image/biocrux Jr..jpg";
import Battleofbands from "../Image/Battle of bands.jpeg";
import Basketball from "../Image/Basketball .PNG";
import Ballismus from "../Image/Ballismus.webp";
import Badminton from "../Image/Badminton .PNG";
import AnimeQuiz from "../Image/Anime no tatakai.jpg";
import Adaptune from "../Image/ADaptune.jpeg";
import Acrylicodyssey from "../Image/Acrylic odyssey.png";

const EventPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { eventName } = useParams();
  const [activeEvent, setActiveEvent] =  useState("All Events");
  const location = useLocation();
  const navevent=location.state;
  const navigate = useNavigate(); 

  const events = {
    "Alfresco": [
      {
        title: "Capture and Conquer",
        registrationFees: "200 per team",
        status: "Inactive",
        image: Capture,
        tagline: '',
        description: "Embrace the thrill of ‘Capture and Conquer’ at our college fest!! “Capture and Conquer” is an interactive scavenger hunt game designed to engage participants in a fun, creative challenge where they must bring, click pictures, or make videos of specific items, places, or activities. The game revolves around a list of challenges, each requiring participants to find and document particular objects, locations, or actions.Unleash your competitive spirit, forge bonds and relish the unforgettable adventure that awaits.Dare to conquer the game.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: {
          perTeam: 200,
          perPerson: '',
          singleBoy: '',
          singleGirl: '',
          Couple: '',
          Solo: '',
          Duet: '',
          perhead: ''
        },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '9 pm',
        rulebook: "https://drive.google.com/file/d/1OyNQbjsaV8uXSsun9jhMyxym8NjHMkFe/view?usp=drive_link",
        link: "https://forms.gle/iAj4YXJDZD9iSNGy7"
      },
      {
        title: "Pictionary",
        registrationFees: "Rs.160 per team",
        status: "Inactive",
        image: Pictionary,
        tagline: '',
        description: "Words or pictures, which one’s got more appeal? Explore Pictionary, and let’s see what you feel.",
        teamSize: "",
        minteamSize: "2",
        maxteamSize: "4",
        fees: { perTeam: "160", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1OPy1mqe8DwenNacgL0hKqnp7IcVv6Xar/view?usp=drive_link",
        link: "https://forms.gle/yvbk1PMNrzbDcvp58"
      },
      {
        title: "Squid Game",
        registrationFees: "70 per person",
        status: "Inactive",
        image: "",
        tagline: '',
        description: "Step into the gripping world of ‘SQUID GAME’ at our thrilling event! Brace yourself for heart-pounding challenges, as you compete in four life-or-death games that demand skill, strategy, and nerves of steel. Win big or face the consequences. Dare to join us in this pulse-pounding, high-stakes adventure!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "", perPerson: "70", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1vYhqvNdkauWv8NI8LA0zNo_4U0mFOJUR/view?usp=drive_link",
        link: "https://forms.gle/Xz9xb8N2Mo8MomQ9A"
      },
      {
        title: "Paper Dance",
        registrationFees: "40 per team",
        status: "Inactive",
        image: Paperdance,
        tagline: '',
        description: " With every step the story unfolds, Paper dance, where magic beholds!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "40", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1BV4KNq1ARgTsIFufl7zuVxWgVOB-Rqgo/view?usp=drive_link",
        link: "https://forms.gle/xorQTwS7zrbEf7cz9"
      },
      {
        title: "Silent Giggles",
        registrationFees: "80 per team",
        status: "Inactive",
        image: "",
        tagline: '',
        description: "Ready for a game that’ll leave you in stitches? In Silent Giggles, participants have to guess the word—but there’s a twist! With headphones on, they won’t hear a thing while their friends try to mime or describe the word. Get ready for misunderstandings and side-splitting laughter as you try to guess without a sound!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "80", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/10fZS2VeYHRqXFpjZmTKyOBm9rhm87On5/view?usp=drive_link",
        link: "https://forms.gle/miudPVVzfqYPf17bA"
      },
      {
        title: "Treasure Hunt",
        registrationFees: "200 per team",
        status: "Inactive",
        image: Treasurehunt,
        tagline: '',
        description: "A treasure hunt as an informal event brings an element of playfulness and excitement to any gathering. Whether it’s a reunion, a backyard party, or a casual get-together with friends, a treasure hunt adds an extra layer of fun and camaraderie. Participants form teams, each armed with a list of clues and riddles that lead them on a thrilling chase to find the hidden treasure. Laughter and cheers echo through the surroundings as the teams race against the clock, solving puzzles and following hints that lead them to various locations. The hunt encourages teamwork, problem-solving, and a dash of friendly competition, fostering bonds between participants as they embark on a shared adventure. In the end, it’s not just about the treasure found but the joyful memories created along the way, making the treasure hunt an unforgettable highlight of the event.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "200", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1kKWF71lNzkYDuHccuKuki5ArbbBHqWLM/view?usp=drive_link",
        link: "https://forms.gle/ttCSdBCqGNgQYDnw7"
      },
      {
        title: "Darty Secrets",
        registrationFees: "30 per person",
        status: "Inactive",
        image: DartySecrets,
        tagline: '',
        description: "It is a casual version of the popular sport that is typically played in a relaxed and social setting, such as a pub or at home with friends. It involves throwing small, pointed darts at a circular dartboard fixed to a wall. The main objective is to score points by hitting specific areas on the board",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "", perPerson: "30", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1ZBUXYzaq8JI4XNuGodGSaaiHuCZc-ghs/view?usp=drive_link",
        link: "https://forms.gle/Q6BKkAfeV9hsut297"
      },
      {
        title: "Songstravaganza",
        registrationFees: "120 per team",
        status: "Inactive",
        image: Songstravaganza,
        tagline: '',
        description: "Welcome to the ultimate song showdown! Get ready for a whirlwind of melodies, lyrics, and laughter as we dive into a series of fun-filled music games. Whether you’re a pop culture pro or just love to sing along, this is your time to shine. Let the music madness begin!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "120", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1-ecNm4ybfrMdt5Zk7BFJcoSl3uVckP0u/view?usp=drive_link",
        link: "https://forms.gle/vCDPacmGsPnzg9af9"
      },
      {
        title: "SwiftMingle",
        registrationFees: "Boys - 120, Girls - 100",
        status: "Inactive",
        image: "",
        tagline: '',
        description: "Speed Dating. Rotate-a-date… Move in or move on",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "1",
        fees: { perTeam: "", perPerson: "", singleBoy: "120", singleGirl: "100", Couple: "", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1gdoCEIQFvni5W-u63jacxs1dkiwIOVom/view?usp=drive_link",
        link: "https://forms.gle/fh1BZ5oRUhGaZery5"
      },
      {
        title: "Tambola",
        registrationFees: "40 per person",
        status: "Inactive",
        image: Tambola,
        tagline: '',
        description: "So many of our childhood memories are incomplete without the game of tambola. Make more precious memories with your friends and elevate your competitive spirit by participating in this fun game.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "", perPerson: "40", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1fC1cmwmZ5h_Y6qBoCOCkZ1rDWzeM7-K_/view?usp=drive_link",
        link: "https://forms.gle/N8bnqkZgSKugnoAp7"
      },
      {
        title: "Evening Amore",
        registrationFees: "Couple entry - 150, Single girls - 100, Single boys - 120",
        status: "Inactive",
        image: EveningAmore,
        tagline: '',
        description: "Participants will embark on a rollercoaster journey of blind dating, experiencing the thrill of meeting someone new, complete with a series of engaging and entertaining games designed to bring out the highs and lows of romance. Whether it’s the spark of connection or the unpredictability of first impressions, this event promises an evening full of excitement, surprises, and memorable moments.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "2",
        fees: { perTeam: "", perPerson: "", singleBoy: "120", singleGirl: "100", Couple: "150", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1osMZ_my48pVu_CvKdJWu5FozP9c-Ai6X/view?usp=drive_link",
        link: "https://forms.gle/PA67eJwyMheS3aqr9"
      },
      {
        title: "Musical Chairs",
        registrationFees: "30 per person",
        status: "Inactive",
        image:  MusicalChairs,
        tagline: '',
        description: "Friends gather in a circle of chairs, with one less chair than the number of participants. ",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "", perPerson: "30", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1OPdOLl-vhdpeff5PLmB_WUY9JK3IDnAi/view?usp=drive_link",
        link: "https://forms.gle/LLRR1ouWmPVfevCG9"
      },
      {
        title: "Soul Sync",
        registrationFees: "80 per team",
        status: "Inactive",
        image: SoulSync,
        tagline: '',
        description: "Are you and your partner truly in sync? Best friends, couples, or even soul mates—this is your chance to prove! Answer questions about each other and see how well you really know your other half. Get it right, and victory is yours. Beware; one wrong answer could break the sync😵. Are you ready to play and see if your bond is unbreakable?",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "80", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1JIjvlMKlT_IBodiv_r6iUu7lAk3txk2U/view?usp=drive_link",
        link: "https://forms.gle/yCZvAcqZHSwJKQ8i9"
      },
      {
        title: "Drape It",
        registrationFees: "50 per team",
        status: "Inactive",
        image: Drapeit,
        tagline: '',
        description: "Participants showcase their draping skills, blending tradition and modernity.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "50", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1CwEs5V95YNRIqVSnPUwFTrIPXPIzwjq0/view?usp=drive_link",
        link: "https://forms.gle/tedaFiCmKMtNae1M6"
      },
      {
        title: "Your Pace or Mine?",
        registrationFees: "120 per team",
        status: "Inactive",
        image: YPOM,
        tagline: '',
        description: "Dumb Charades",
        teamSize: "",
        minteamSize: "3",
        maxteamSize: "5",
        fees: { perTeam: "120", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1Y1FfOlcL2DeLq0cQgePHc-6nZsQEEJw5/view?usp=drive_link",
        link: "https://forms.gle/jV4jKHiFm4kaGkSR8"
      },
      // {
      //   title: "Balloon Burst",
      //   registrationFees: "40 per team",
      //   status: "Inactive",
      //   image: "",
      //   tagline: '',
      //   description: "Join the ultimate Balloon Burst Frenzy, where the fun never stops and every pop counts!! It’s a race against the clock and your friends- who will rise to the top as the ultimate balloon buster? Ready, set, burst!!!",
      //   teamSize: "",
      //   minteamSize: "1",
      //   maxteamSize: "15",
      //   fees: { perTeam: "40", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", },
      //   contact1Name: '',
      //   contact1: '',
      //   contact2Name: '',
      //   contact2: '',
      //   contact3Name: '',
      //   contact3: '',
      //   prize: "",
      //   date: '',
      //   venue: '',
      //   time: '',
      //   rulebook: "",
      //   link: ""
      // },
      // More events...
    ],
    "Chorea": [
      {
        title: "Nritya Sangam",
        registrationFees: "Solo: 150/- | Duet: 250/- | Group: 80/-(per head)",
        status: "Inactive",
        image: Nrityasangam ,
        tagline: 'The Confluence of Classical Elegance',
        description: "A Classical/Folk dance competition is a celebration of tradition creativity and the enduring legacy of classical and folk dances creating a captivating experience that honours the depth and diversity of our cultural roots.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "20",
        fees: { perTeam: "", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "150", Duet: "250", perhead: "80" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1keAHz4LTwfOBzJbyDypOnCOAoj6bhNe3/view?usp=sharing",
        link: "https://forms.gle/EwXsXLp4ViiaeC487"
      },
      {
        title: "Ballismus",
        registrationFees: "Solo: 150/- | Duet: 250/- | Group: 80/-(per head)",
        status: "Inactive",
        image: Ballismus,
        tagline: 'Dust off your boots and Dance.',
        description: "  Get ready to mesmerize the audience with your dancing skills! Ballismus is an inter-university dance competition that brings together talented dancers from across the country. Whether you’re a seasoned performer or an enthusiastic beginner, this platform is yours to shine. Choose your style- Western, Bollywood, or Fusion - and take the stage by storm!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "150", Duet: "250", perhead: "80" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1VpcB9BTm26S2SBMvxz4F0seVD5yGfUpA/view?usp=sharing",
        link: "https://forms.gle/TdYq3iqqrhgfjUAs6"
      },
      {
        title: "Street Blaze",
        registrationFees: "Solo: 100/- | Duet: 150/- | Group: 50/-(per head)",
        status: "Inactive",
        image: StreetBlaze,
        tagline: 'Turn up the heat, set the street on blaze',
        description: "Get ready to witness the energy passion and raw talent as dancers come together for an electrifying battle of rhythm and style. This is more than just a competition—it's a celebration of culture creativity and the unbreakable spirit of street dance. So, lace up your kicks, turn up the music, and get ready to feel the beat. The streets are calling—let the battles begin!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "100", Duet: "150", perhead: "50" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1OUrata8zL2Bz7l_GFeDwpbh1tNq0ysdb/view?usp=sharing",
        link: "https://forms.gle/ZQncr7wdD9E2kAHQA"
      },
      {
        title: "Adaptune",
        registrationFees: "Solo: 80/- | Duet: 150/-",
        status: "Inactive",
        image: Adaptune,
        tagline: 'Step into the spotlight-Adaptune awaits your flair for dance',
        description: "Join us for a dynamic and exhilarating dance experience at 'ADAPTUNE'. In this one-of-a-kind event you'll dance to spontaneously selected viral songs revealed just one minute before you hit the floor. Whether you're a seasoned dancer or a novice looking for fun, this event promises a high-energy, interactive experience where every moment counts. Gather your friends, bring your best moves, and let the rhythm guide you through an unforgettable night of dance and excitement!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "2",
        fees: { perTeam: "", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "80", Duet: "150", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1g-bh9C8WaEoGqA-BtNsLwGqYs7_6RQmt/view?usp=sharing",
        link: "https://forms.gle/BhUFYRNz89zipBwk6"
      },
      {
        title: "Blossoming Steps – Couple Dance",
        registrationFees: "200 per couple",
        status: "Inactive",
        image: BlossomingSteps,
        tagline: 'Growing Together, One Dance at a Time',
        description: "Blossoming Steps' is a couple dance event that takes participants through a journey of relationship growth using Knapp's Model as inspiration.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "2",
        fees: { perTeam: "", perPerson: "", singleBoy: "", singleGirl: "", Couple: "200", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/115tiaKOydNJt64b5S2BdABPn9Q9JH933/view?usp=sharing",
        link: "https://docs.google.com/forms/d/e/1FAIpQLSfwu56HI6wrbXSFdy_1HWbYAD_-F_qPB71o4HE-RACx7iT3FA/viewform?usp=sf_link"
      },

    ],
    "Kalakriti": [

      {
        title: "Fantasy Faces",
        registrationFees: "₹100 per team",
        status: "Inactive",
        image: Fantasyfaces,
        tagline: 'Transforming faces, from blank canvases to living art!',
        description: "Prepare to witness a kaleidoscope of colours, dazzling designs, and artistic mastery as participants transform faces into mesmerizing works of art.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "100", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '10-Oct-2024',
        venue: 'Mini Audi',
        time: '10:30AM- 12:30 PM',
        rulebook: "https://drive.google.com/file/d/1NrMerwfHuF9ouFYzvTwyEwfY6DRCgFZU/view?usp=drive_link",
        link: "https://forms.gle/n4dsMxyxBJJFJAeV6"
      },

      {
        title: "Caffeine Creations",
        registrationFees: "₹30 per individual",
        status: "Inactive",
        image: Caffeinecreations,
        tagline: 'From Beans to Brushes,awaken your creativity with the mesmerizing aroma of art!',
        description: " Join our Coffee Painting Competition and let the rich hues of coffee brew beauty on your canvas.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "30", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '10-Oct-2024',
        venue: 'Mini Audi',
        time: '1:30-3:00 PM',
        rulebook: "https://drive.google.com/file/d/1YD3GHczjsnO5MIqSA70o5oKc650yl5Zn/view?usp=drive_link",
        link: "https://forms.gle/pk2yhd9K5BiNkTd46"
      },
      {
        title: "Brushless Strokes",
        registrationFees: "₹50 per individual",
        status: "Inactive",
        image: Brushlessstrokes,
        tagline: 'Explore the world beyond brushes & embrace the art of free expression!',
        description: "Unleash your hidden creativity and think outside the box to create a masterpiece with anything other than a brush.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "50", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '11-Oct-2024',
        venue: 'Mini Audi',
        time: '1:30-3:30 PM',
        rulebook: "https://drive.google.com/file/d/1jbNiS-caufF9clRf34ay9h_HH5ojqZY9/view?usp=drive_link",
        link: "https://forms.gle/btLcQrAa4UhATvZu7"
      },
      {
        title: "Acrylic Odyssey",
        registrationFees: "₹80 per individual",
        status: "Inactive",
        image: Acrylicodyssey,
        tagline: 'Bold pigments blend, a symphony of art,With acrylic strokes, masterpieces start.',
        description: "With a boundless palette of colors and a canvas to make your own, let your brushstrokes tell a story, and vie for the title of The Master of Acrylics",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "80", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '10:00AM- 12:30 PM',
        venue: 'Mini Audi',
        time: '12-Oct-2024',
        rulebook: "https://drive.google.com/file/d/1ufw8E8UmB1gz-46uPcWgXV9Qrjij-rea/view?usp=drive_link",
        link: "https://forms.gle/zPt19DUj3Cwadj2X8"
      },

      {
        title: "Tattoo Tales",
        registrationFees: "₹50 per team",
        status: "Inactive",
        image: TattooTales,
        tagline: 'Transient Tattoos, Lasting Impressions!',
        description: "Unleash the full extent of your artistic prowess as you blend the vibrancy of acrylic paint with deft brushstrokes to craft stunning temporary tattoos.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "50", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '12-Oct-2024',
        venue: 'Mini Audi',
        time: '1:30-3:30 PM',
        rulebook: "https://drive.google.com/file/d/1FBhXqt3drhK5KzursR0hWPBWXE6kgAaZ/view?usp=drive_link",
        link:"https://forms.gle/ZuzAqzwXXcsno4vG7"
      },
      {
        title: "Contrast Chronicles",
        registrationFees: "₹40 per individual",
        status: "Inactive",
        image: ContrastChronicles,
        tagline: 'Sketching whispers, dreams take shape With strokes of graphite, worlds escape.',
        description: "Welcome to a B&W sketching competition, where we eagerly anticipate your monochrome masterpiece to dazzle us.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "40", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '13-Oct-2024',
        venue: 'Mini audi',
        time: '1:30-3:00 PM',
        rulebook: "https://drive.google.com/file/d/1C1Y3_07FvmLL-jal7AKMpMp26i7CWaxE/view?usp=drive_link",
        link: "https://forms.gle/JLJ9GakYVRPPYcKH9"
      },
       {
        title: "Cupful of Doodles",
        registrationFees: "₹20 per individual",
        status: "Inactive",
        image: Cupful,
        tagline: 'Get ready to embark on an unforgettable journey of ink- spiration!',
        description: "Calling all doodle enthusiasts and artistic daydreamers! This is your chance to let your pen dance on paper cups and weave stories through your strokes.",
      teamSize: "",
      minteamSize: "1",
      maxteamSize: "4",
      fees: { perTeam: "", perPerson: "20", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
      contact1Name: '',
      contact1: '',
      contact2Name: '',
      contact2: '',
      contact3Name: '',
      contact3: '',
      prize: "",
      date: '13-Oct-2024',
      venue: 'Mini Audi',
      time: '1:30 PM- 3:00 PM',
      rulebook: "https://drive.google.com/file/d/1Vq-_aiT1tTjwukbXvwjrvaTyqnnftwGc/view?usp=drive_link",
      link: "https://forms.gle/KjcZJNQKYL5mi1oc8"
      },

      {
        title: "Splash Tees",
        registrationFees: "₹150 per individual",
        status: "Inactive",
        image: Splashtees,
        tagline: 'Brushstrokes on fabric, colors ignite,T shirt transformed, a wearable delight!',
        description: "Get ready to unleash your inner artist and splash your creativity onto a humble canvas of a T shirt in our exhilarating T shirt painting competition.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "150", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '  14-Oct-2024 ',
        venue: 'Mini Audi',
        time: '10:00AM- 12:00 PM',
        rulebook: "https://drive.google.com/file/d/1rh8QVqUhSm860rdUk6Kqn3DQEx4LJNfw/view?usp=drive_link",
        link: "https://forms.gle/WUT3S18HVhiRSgHh7"
      },
      {
        title: "Mehendi Mania",
        registrationFees: "₹30 per individual",
        status: "Inactive",
        image: mehendimania,
        tagline: 'An imprint of tradition, nature’s blossoms on your palms!',
        description: "Let the rich earthy scent of mehndi, guide your creativity and lead you to the finish line, in this mehndi designing competition.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "30", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: ' 14-Oct-2024 ',
        venue: 'Mini Audi',
        time: '1:30PM- 3:00 PM',
        rulebook: "https://drive.google.com/file/d/1JgOKMhxH-S7jN5d-ECLJKeSXolrpno8u/view?usp=drive_link",
        link: "https://forms.gle/VLECJmo986s7MK6Y9"
      },
      {
        title: "Brush Of Pebbles",
        registrationFees: "₹50 per individual",
        status: "Inactive",
        image: "",
        tagline: "On rugged stones, colors bloom and play Nature's canvas adorned, a rock's display!",
        description: "Welcome to this stone painting competition, where rocks transform into galleries. An amazing blend of human imagination with the beauty of nature.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "50", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '14-Oct-2024',
        venue: 'Mini Audi',
        time: '3:15PM- 5:15PM',
        rulebook: "https://drive.google.com/file/d/1ZZym4B5AXPL_xBMn-B38Vns9ZxgOkyKk/view?usp=drive_link",
        link: "https://forms.gle/fqc9mfWfciWMqmM27"
      },

    ],
    "LITtMania": [
      {
        title: "Cineholics",
        registrationFees: "Rs.60 for team of 3 || Rs. 50 for team of 2  || Rs.30 for lonewolves",
        status: "Inactive",
        image: Cineholics,
        tagline: '',
        description: " Your ultimate movie and mega fandom quiz experience! Dive into the captivating world of cinema, web series, and OTT platforms as we test your knowledge and passion for the screen. Whether you're a film buff or a series enthusiast, this is your chance to shine and enjoy a thrilling challenge. Gather your friends, get ready to engage, and most importantly, have a fantastic time. Let the quiz begin and may the best cinephiles win!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "3",
        fees: { perTeam: "", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "", lonewolves: "30", twolonewolves: "50", threelonewolves: "60" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '10 October, 2024',
        venue: 'LT3',
        time: '9:00am',
        rulebook: "https://drive.google.com/file/d/140YuUwe20Od1f9VubrJnoeJuN2Ptc9xp/view?usp=sharing",
        link: "https://forms.gle/Q5pdfPa3rpkUNmA37"
      },
      {
        title: "Cognizzia",
        registrationFees: "Rs.180/- per team",
        status: "Inactive",
        image: Cognizzia,
        tagline: '',
        description: "COGNIZZIA is an engaging and informative General Quiz that challenges participants with a diverse range of questions across various topics. Whether you are a trivia enthusiast or simply seeking to expand your knowledge, COGNIZZIA offers an enjoyable experience that stimulates the mind. Test your expertise on history, science, pop culture, geography, and much more, as you navigate through thought-provoking questions and discover fascinating facts. Sharpen your cognitive skills and have fun while exploring a world of intriguing information with COGNIZZIA: General Quiz.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "180", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '11th October , 2024 ',
        venue: '',
        time: '9:00 A.M',
        rulebook: "https://drive.google.com/file/d/1XcjO3pjdMVKS8sFhzVNnqaXyRo1zufJp/view?usp=sharing",
        link: "https://forms.gle/YMijC99gEmvZoeHZ7"
      },
      // {
      //   title: "Biocrux Jr.(MedQuiz)",
      //   registrationFees: "Rs. 180/- per team",
      //   status: "Inactive",
      //   image: biocruxJr,
      //   tagline: '',
      //   description: "Subjects: All pre and para clinical subjects",
      //   teamSize: "",
      //   minteamSize: "1",
      //   maxteamSize: "4",
      //   fees: { perTeam: "180", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
      //   contact1Name: '',
      //   contact1: '',
      //   contact2Name: '',
      //   contact2: '',
      //   contact3Name: '',
      //   contact3: '',
      //   prize: "",
      //   date: '12/10/2024',
      //   venue: '',
      //   time: '09.00 AM onwards',
      //   rulebook: "https://drive.google.com/file/d/1T3xWforNBZ8Icirw6K2fUD36Hj3FkfgK/view?usp=sharing",
      //   link: "https://forms.gle/oy9tEnWickw7EF7Y8"
      // },
      {
        title: "Biocrux Sr.(MedQuiz)",
        registrationFees: "Rs. 210/- per team (Only 1 intern per team is allowed)",
        status: "Inactive",
        image: BiocruxSr,
        tagline: '',
        description: "Subjects: All relevant clinical knowledge!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "210", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '13/10/2024',
        venue: 'Main Auditorium',
        time: ' 09.00 AM onwards',
        rulebook: "https://drive.google.com/file/d/1IbznYefZg2ylKMglranJlZcpxK7UKtQB/view?usp=sharing",
        link: "https://forms.gle/F4kZ7VUNVDEEUV3W7"
      },
      {
        title: "Anime No Tatakai",
        registrationFees: "Rs. 180/- for a team of 4",
        status: "Inactive",
        image: AnimeQuiz,
        tagline: '',
        description: "Get ready to test your knowledge of the vibrant world of anime .Join our anime quiz and showcase your expertise on various genres,characters and storylines . From classic mainstream series (Naruto, one-piece ,Bleach,death note , one punch man ,etc)to the latest releases(demon slayer , Jjk , chainsaw man , etc), our questions and rounds will challenge even the most dedicated otakus.Our quiz revolve around genre like action , romance, shounen , seinen , drama , etc and includes little to no manga , manhwa or manhua questions .",
        teamSize: "",
        minteamSize: "4",
        maxteamSize: "4",
        fees: { perTeam: "180", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '14 October 2024',
        venue: 'LT3',
        time: '9:00am',
        rulebook: "https://drive.google.com/file/d/19NCk4X01AmxMtOBm_PKBlJVlNxk9yskq/view?usp=sharing",
        link:"https://forms.gle/P2GYqXQ4WR64gqSQ7"
      },
      {
        title: "Iconic Impressions ",
        registrationFees: "Rs. 50 per person",
        status: "Inactive",
        image: IconicImpressions,
        tagline: '',
        description: "Join us in this epic showcase of talents wherein participants enact the characters (from movies, books, sitcoms etc.) that they have grown up watching and have loved them dearly. Let the wheel of fortune do its wonders and be the chosen one reenacting your character. And the best part, let your creativity flow by imagining them in a totally different scenario.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "50", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1YiNrZ0aK0IYXcLo_4VKBmsD2UYuYQxOi/view?usp=sharing",
        link:"https://forms.gle/EMUg3N3MK9Rqfig49"
      },
      {
        title: "Unstory",
        registrationFees: "Rs. 120/- per team",
        status: "Inactive",
        image: Unstory,
        tagline: '',
        description: "An event bringing out the perfect blend of your writing prowess and quick thinking skills to string words together and turn them into a beautiful story but not in the conventional way storytellers do. Pyrexia brings you 'unstory' where let your story be a joint effort with your teammates and the topic be oblivious to you till the clock starts ticking.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "120", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1Iiw3MvcnPWb1__wrkoxQji4N2DfN825T/view?usp=sharing",
        link:"https://forms.gle/1AnbygQnogpMvM7KA"
      }, {
        title: "Rip n Stitch",
        registrationFees: "Rs. 50 per person",
        status: "Inactive",
        image: RipnStitch,
        tagline: '',
        description: "This event is about seeing the world through a fresh lens, where words already exist but their new arrangement tells a different story. Dive into the world of found poetry, where creativity knows no bounds and inspiration is everywhere.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "50", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1li9hZfR5jOeJZ4c4qxetHjgvC46L3j_H/view?usp=sharing",
        link:"https://forms.gle/kVe43n8fHwNyzNDV8"
      },
      {
        title: "Cupid's Countdown",
        registrationFees: "Rs.60/- per person",
        status: "Inactive",
        image: Cupid,
        tagline: '',
        description: " An event of literary connections where book lovers, aspiring writers, and literary enthusiasts come together to share their passions in a fast-paced, engaging format. In this unique event, you'll have the opportunity to meet a diverse range of fellow bibliophiles and discuss your favorite books, authors, and literary genres in brief, dynamic conversations that would ultimately decide your fate in the game.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "60", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1g1lKpyGvXg9oDmJpTQtoxgAS4e6Yj2E5/view?usp=sharing",
        link:"https://forms.gle/vVchurakNnZadGS16"
      },
      {
        title: "Kavyotsav:",
        registrationFees: "Rs. 60/- per person",
        status: "Inactive",
        image: Kavyotsav,
        tagline: '',
        description: "एक ऐसा मंच जहाँ शब्दों की अद्भुत कलाकारी और भावनाओं की गहराई का अनुभव होता है। यह साहित्यिक मिलन का अवसर है, जहाँ हर कविता एक नई सोच और दृष्टिकोण को प्रस्तुत करती है।",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "60", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/16MovwWtQu7jj-t_AGAMsJxSRdeadYIC6/view?usp=sharing",
        link:"https://forms.gle/4rkzEWgussYkPwhx7"
      },
      {
        title: "Prose the Pictures",
        registrationFees: "Rs. 50/- per person",
        status: "Inactive",
        image: Prose,
        tagline: '',
        description: "Welcome to Rhapsody, where a series of evocative images guide your words to create a story that resonates them. Each picture holds a piece of a story—waiting for you to bring it to life.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "50", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1wr0vnmH0GphjA-wBNADcBcVkEtqA8VCj/view?usp=sharing",
        link:"https://forms.gle/8Uvw5ht3YpUj5Myf7"
      },
      // {
      //   title: "Biocrux Jr",
      //   registrationFees: "Rs. 180/- per team || Rs. 60/- per person",
      //   status: "Inactive",
      //   image: biocruxJr,
      //   tagline: '',
      //   description: "Subjects: All pre and para clinical subjects.",
      //   teamSize: "",
      //   minteamSize: "1",
      //   maxteamSize: "4",
      //   fees: { perTeam: "180", perPerson: "60", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
      //   contact1Name: '',
      //   contact1: '',
      //   contact2Name: '',
      //   contact2: '',
      //   contact3Name: '',
      //   contact3: '',
      //   prize: "",
      //   date: '',
      //   venue: '',
      //   time: '',
      //   rulebook: "https://drive.google.com/file/d/1L9qMfCZdMDlXgDPMq3P1zP8zKvfXKzkI/view?usp=drive_link"
      // },
      {
        title: "The War of Wits(Debate Competition)",
        registrationFees: "Rs. 60/- per person",
        status: "Inactive",
        image: WarofWits,
        tagline: '',
        description: " Step into the cerebral arena of intellectual duels, where minds clash and ideas ascend to their zenith. Prepare to embark on a voyage of rhetoric and wit, where the art of persuasion takes center stage. Engage in the prestigious Parliamentary Debate Competition and let your arguments resonate with eloquence and conviction.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "60", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/12RZfH8LIqvh3pjB3U0xgsJ62SbZ5Yfl2/view?usp=sharing",
        link:"https://forms.gle/fCMUkxaTqoJ41iHS6"
      },
      {
        title: "JAM",
        registrationFees: "Rs. 60/- per person",
        status: "Inactive",
        image: JAM,
        tagline: '',
        description: " Ladies and gentlemen, welcome to our exciting Just a Minute competition (JAM Session) Hereby, have two thrilling rounds of wordplay and wit where our competitors will showcase their skills in maintaining fluency, precision, and creativity under the pressure of the ticking clock.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "60", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1zTujAb0GTLBEpMF53ah7igRtVR0fdQ8P/view?usp=sharing",
        link:"https://forms.gle/gSSjY1xxFCugHxDa9"
      },



    ],
    "Sinfonia": [
      {
        title: "TARANG: Indian Singing",
        registrationFees: "Solo: ₹150 | Duet: ₹200 | Group: ₹400",
        status: "Inactive",
        image: Tarang,
        tagline: 'Soulful symphonies from the subcontinent',
        description: "Tarang - The Indian singing competition offers a stellar platform to showcase your expertise in Indian light music semi-classical Bollywood or folk genres. Embrace the opportunity to captivate the audience with your melodious voice!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "150", Duet: "200", perhead: "", groupTeam: "400" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1IsYoMANTOpeGmKkyHEH0ICmGyVg-CuL7/view?usp=drive_link",
        link: "https://forms.gle/bUhhHbDNzTNAYshQ9"
      },
      {
        title: "Euphonia: Western Singing",
        registrationFees: "Solo: ₹150 | Duet: ₹200 | Group: ₹400",
        status: "Inactive",
        image: Euphonia,
        tagline: 'Euphonia Glory—Where Every Note Tells a Story!',
        description: "Euphonia: Western Singing Competition - Witness the raw power of the human voice in this electrifying singing competition where harmony reigns supreme. Each note, each harmony is pure talent and skill. Feel the energy as participants compete, blending voices in intricate arrangements that push the boundaries of vocal music. Who will rise above and claim the title of Euphonic champion?",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "150", Duet: "200", perhead: "", groupTeam: "400" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1xRuSZXRGbB-KLvV_xECnP04m9wsxzhBO/view?usp=drive_link",
        link: "https://forms.gle/PMpS5hDxqMZyBvm88"

      },
      {
        title: "METALLICA",
        registrationFees: "Single: Rs 200 | Duet: Rs 300",
        status: "Inactive",
        image: Metallica,
        tagline: 'Unleash Your Instrumental Fury and Set the Stage Ablaze',
        description: "Are you ready to let your instruments speak and your skills dazzle? Join us at Metallica where your chance to shine awaits! This high-energy competition invites instrumentalists from all backgrounds to showcase their prowess and electrify the stage with their talent. Whether you wield a guitar, drum, violin, or any other instrument, Metallica is your platform to demonstrate your virtuosity and creativity. Compete for thrilling prizes and the chance to become the ultimate instrumental champion. Don't miss this opportunity to make your mark and set the stage on fire with your exceptional skills. Step up, show off, and let your music roar!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "200", Duet: "300", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/10gkv29sR63ilc9Uec2282_siGZdNcN3f/view?usp=drive_link",
        link: "https://forms.gle/oimAW6WLcu3JTzXL9"
      },
      {
        title: "BATTLE OF BANDS",
        registrationFees: "Early bird:Rs 750 per band || On spot:Rs 1000 per band",
        status: "Inactive",
        image: Battleofbands,
        tagline: 'Ignite the Stage Rock the Fest',
        description: "Are you ready to turn up the volume and unleash your musical prowess? Join us at Pyrexia 4.0 for the ultimate Battle of the Bands! This electrifying competition is your chance to showcase your band's talent, energy, and creativity in front of an enthusiastic crowd. Compete for top honors and exciting prizes as you go head-to-head with the best musical acts in college. Whether you rock, groove, or experiment with unique sounds, this is your moment to shine. Sign up now and get ready to claim your spot as the reigning champions of Pyrexia!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "750", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1KME7mWpIdjJ9mdQW_KNL6LRtJbOPfqIH/view?usp=drive_link",
        link: "https://forms.gle/3URH4uxUwykUvkai6"
      },
      {
        title: "RHYTHM REVOLUTION - RAP BATTLE AND BEATBOXING",
        registrationFees: "Solo: ₹100 | Duet: ₹150",
        status: "Inactive",
        image: "",
        tagline: 'Voice the words. Dominate the beat.',
        description: "Unleash your lyrical and rhythmic genius at our Rap and Beatboxing Battle! This competition is the right opportunity for you to battle it out with fellow contestants in a high-energy face-off, where creativity, flow, and originality reign supreme. Show us what you've got and claim the title of the Revolutionist!",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "100", Duet: "150", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1bYcp1_0lwpZKrcQNzbO3LH-LIUDC7uOK/view?usp=drive_link",
        link: "https://forms.gle/WkEwKF8Ey23FKUERA"
      },

    ],
    "Thespians": [
      {
        title: "Nukkad Natak",
        registrationFees: "Rs 500 per team",
        status: "Inactive",
        image: NukkadNatak,
        tagline: 'Drama Beyond the Walls',
        description: "A Nukkad Natak is a form of street theatre in India that uses a mix of dialogue, music, and dance to address social issues and entertain audiences. Performed in open spaces, it aims to engage the public with its vibrant and interactive storytelling.",
        teamSize: "4-20",
        minteamSize: "4",
        maxteamSize: "20",
        fees: { perTeam: "500", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1KRujOaqWHpvGSdtC6UEDHt2OSjbadGhK/view?usp=sharing",
        link: "https://forms.gle/ZxY4RkBcBWoXz7Lg6"
      },
      {
        title: "Echoes of Expressions: Monoact and Mime competition",
        registrationFees: "Solo-Rs 120 || Group(4-10 members)-Rs 500 per team",
        status: "Inactive",
        image: Echoes,
        tagline: 'One Stage, Infinite Expressions',
        description: "The Monoact Competition is an exciting theatrical event that showcases the talent and versatility of participants as they take the stage alone to perform captivating solo acts. Mime is a unique and captivating form of non-verbal performance art that relies solely on body movements, gestures, facial expressions, and actions to convey emotions, tell stories, and communicate with the audience.",
        teamSize: "1||4-10",
        teamvalue: "1",
        minteamvalue: "4",
        minteamSize: "1",
        maxteamSize: "10",
        fees: { perTeam: "500", perPerson: "120", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" ,group4to10:"500"},
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1MaStu-z_AHXbB2QUnxguP-3X9_1Pfl2x/view?usp=sharing",
        link: "https://forms.gle/AxabSdv2QQFoLeLZ9"
      },

      // Add more events as needed
      {
        title: "COMIC-COMBAT: STAND-UP COMEDY",
        registrationFees: "Rs 100 per person",
        status: "Inactive",
        image: ComicCombat,
        tagline: 'Where Jokes Take the Stage and Laughter Reigns Supreme',
        description: "Comic Combat is a vibrant intercollege comedy competition that showcases the freshest comedic talent in PYREXIA 2024. Participants face off in a high-energy contest delivering their best stand-up routines to win over the audience and judges. Expect a night filled with memorable performances, spontaneous laughs, and fierce competition as the funniest college comedians battle it out for the top spot.",
        teamSize: "3-5",
        minteamSize: "3",
        maxteamSize: "5",
        fees: { perTeam: "", perPerson: "100", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1jIUS4L5_yU9vV6TMtdb1rytF5CyNMcn6/view?usp=sharing",
        link: "https://forms.gle/jvUEeYcR2rEoYkv48" // This can be optional
      },
      // Add more events as needed
      {
        title: "MADD ANGLE",
        registrationFees: "Rs 100 per person",
        status: "Inactive",
        image: MaddAngle,
        tagline: 'DRAMA bhi DEAL bhi',
        description: "In Madd Angle, participants (solo or team) will be given a random prop impromptu and will have to bring it to life through either a captivating sales pitch or a dramatic performance. Whether it's through persuasive storytelling or engaging drama, each performance promises to be a one-of-a-kind experience.",
        teamSize: "3-5",
        minteamSize: "3",
        maxteamSize: "5",
        fees: { perTeam: "", perPerson: "100", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1aK-Bzbnv5IQqk-w0CYvoS9jdpqUJ3dMy/view?usp=sharing",
        link: "https://forms.gle/dKdTKnd6a4BYmReS9"
      },
    ],
    "Thunderbolt": [
      {
        title: "Mortal Kombat",
        registrationFees: "Rs 75/-",
        status: "Inactive",
        image: "",
        tagline: '',
        description: "Knockout matches with the winner from each pool advancing to the finals.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "75", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "Rs 1500/-",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1zHZR1yCkuo3CRVBGySaGyqF_1QrsAqIM/view?usp=drive_link",
        link: "https://forms.gle/paFvkghbLBPNFLZR9"
      },
      {
        title: "COD MOBILE (MULTIPLAYER)",
        registrationFees: "₹300 per team",
        status: "Inactive",
        image: "",
        tagline: '',
        description: "Multiplayer COD Mobile tournament with knockout matches, maps, and modes fixed after fixtures.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "300", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "Rs 4000/-",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1uMjBhDTzLcb0MG7YOYi4ZZ54KCjg9Kmv/view?usp=drive_link",
        link: "https://forms.gle/Cjeamw3RciVZvc349"
      },
      {
        title: "TEKKEN",
        registrationFees: "Rs 75/-",
        status: "Inactive",
        image: "",
        tagline: '',
        description: "Knockout matches with the winner from each pool advancing to the finals.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "75", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "Rs 450/-",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1iwrVdEmrzlnQLJuGyNalTCw2F76s8ryf/view?usp=drive_link",
        lik: "https://forms.gle/brb5PFCbhJ1S5WBd9"
      },
      {
        title: "FIFA",
        registrationFees: "Rs 180/-",
        status: "Inactive",
        image: "",
        tagline: '',
        description: "Knockout FIFA matches with winners from each pool advancing to the finals.",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "", perPerson: "180", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "Rs 2500/-",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1Nh-C3Div0TMFjYe-oF96BMwCWCnaraCf/view?usp=drive_link",
        link: "https://forms.gle/zLCooSckUoHbSP2e7"
      },
      // {
      //   title: "BGMI (BATTLE ROYALE)",
      //   registrationFees: "₹400 per team",
      //   status: "Inactive",
      //   image: "",
      //   tagline: '',
      //   description: "BGMI tournament with league stage and final round, top 16 teams advance to final.",
      //   teamSize: "",
      //   minteamSize: "1",
      //   maxteamSize: "4",
      //   fees: { perTeam: "400", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
      //   contact1Name: '',
      //   contact1: '',
      //   contact2Name: '',
      //   contact2: '',
      //   contact3Name: '',
      //   contact3: '',
      //   prize: "₹10000",
      //   date: '',
      //   venue: '',
      //   time: '',
      //   rulebook: "https://drive.google.com/file/d/1r__Vl1AUoiKjflXMT41cyhbbo7I_dHeP/view?usp=drive_link",
      //   link: "https://forms.gle/XUkESM29CPTYtqc66"
      // },
      // {
      //   title: "BGMI (TEAM DEATH MATCH)",
      //   registrationFees: "₹200 per team",
      //   status: "Inactive",
      //   image: "",
      //   tagline: '',
      //   description: "BGMI Team Death Match with 4 players per team, first team to 40 kills wins.",
      //   teamSize: "",
      //   minteamSize: "1",
      //   maxteamSize: "4",
      //   fees: { perTeam: "200", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
      //   contact1Name: '',
      //   contact1: '',
      //   contact2Name: '',
      //   contact2: '',
      //   contact3Name: '',
      //   contact3: '',
      //   prize: "Trophy + Cash prize",
      //   date: '',
      //   venue: '',
      //   time: '',
      //   rulebook: "https://drive.google.com/file/d/1r__Vl1AUoiKjflXMT41cyhbbo7I_dHeP/view?usp=drive_link",
      //   link: "https://forms.gle/XUkESM29CPTYtqc66"
      // },
    ],
    "Velocity": [

      {
        title: "Boys Basketball 5V5",
        registrationFees: "2000",
        status: "Inactive",
        image: Basketball,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "5",
        maxteamSize: "5",
        fees: { perTeam: "2000", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1f12khueXZe0T6A8kPDB_fZc4H0vi3wSs/view?usp=drive_link",
        link: "https://forms.gle/vydKMDUt3f1sd2Nz9"
      },
        {
        title: "Girls Basketball 5V5",
        registrationFees: "1300",
        status: "Inactive",
        image: Basketball,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "5",
        maxteamSize: "5",
        fees: { perTeam: "1300", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "2100(First)/1500(Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1f12khueXZe0T6A8kPDB_fZc4H0vi3wSs/view?usp=drive_link",
        link: "https://forms.gle/i44s17sujks8sRjf9"
      },
      {
        title: "Boys Basketball 3V3",
        registrationFees: "1000",
        status: "Inactive",
        image:Basketball,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "3",
        maxteamSize: "3",
        fees: { perTeam: "1000", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "5100 (First)/2100(Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1f12khueXZe0T6A8kPDB_fZc4H0vi3wSs/view?usp=drive_link",
        link: "https://forms.gle/fwAbbaXFGxFSoNQr7"
      },
        {
        title: "Girls Basketball 3V3",
        registrationFees: "700",
        status: "Inactive",
        image:Basketball,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "3",
        maxteamSize: "3",
        fees: { perTeam: "700", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "5100 (First)/2100(Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1f12khueXZe0T6A8kPDB_fZc4H0vi3wSs/view?usp=drive_link",
        link: "https://forms.gle/Dpb42tf7jhRj224M6"
      },
              {
        title: "Cricket",
        registrationFees: "5100",
        status: "Inactive",
        image:Cricket,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "",
        maxteamSize: "",
        fees: { perTeam: "5100", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1plF3P2QmZa93NQyBtnB566C_k_nQm9nc/view?usp=drivesdk",
        link: "https://forms.gle/AtfDVZRcpxMFAszg7"
      },
             {
        title: "Carrom Singles",
        registrationFees: "Rs 70 ",
        status: "Inactive",
        image:Carrom,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "",
        maxteamSize: "",
        fees: { perTeam: "70", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" ,singles:"70",doubles:"150",mixedDoubles:"150"},
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "5100 (First)/2100(Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1gumH1_3cnsfizktICeOiApUK9DT_aub-/view?usp=drivesdk",
        link: "https://forms.gle/cKbH2UyBDC3KAN2D7"
      },
      {
        title: "Carrom Doubles",
        registrationFees: "Rs 150",
        status: "Inactive",
        image:Carrom,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "",
        maxteamSize: "",
        fees: { perTeam: "150", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" ,singles:"70",doubles:"150",mixedDoubles:"150"},
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "5100 (First)/2100(Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1gumH1_3cnsfizktICeOiApUK9DT_aub-/view?usp=drivesdk",
        link: "https://forms.gle/cKbH2UyBDC3KAN2D7"
      },
      {
        title: "Carrom Mixed Doubles",
        registrationFees: "Rs 150",
        status: "Inactive",
        image:Carrom,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "",
        maxteamSize: "",
        fees: { perTeam: "150", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "5100 (First)/2100(Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1gumH1_3cnsfizktICeOiApUK9DT_aub-/view?usp=drivesdk",
        link: "https://forms.gle/cKbH2UyBDC3KAN2D7"
      },
      {
        title: "Table Tennis Singles",
        registrationFees: "150",
        status: "Inactive",
        image: TableTennis,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "150", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "1100 (First), 700 (Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1XnONVNs2A3wyscu3RSvj7Lumv3ete_Dj/view?usp=drive_link",
        link: "https://forms.gle/TRmVukDEyVtESqZt5"
      },
      {
        title: "Table Tennis Doubles",
        registrationFees: "300",
        status: "Inactive",
        image: TableTennis,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "20",
        fees: { perTeam: "300", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "2100 (First), 1100 (Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1mtzo0-RRNJ8zVRqc0KI2TL2BtY063H6B/view?usp=drive_link",
        link:"https://forms.gle/TRmVukDEyVtESqZt5"
      },
      {
        title: "Table Tennis Mixed Doubles",
        registrationFees: "300",
        status: "Inactive",
        image: TableTennis,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "300", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "2100 (First), 1100 (Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1mtzo0-RRNJ8zVRqc0KI2TL2BtY063H6B/view?usp=drive_link",
        link:"https://forms.gle/TRmVukDEyVtESqZt5"
      },
      {
        title: "Girls Kabaddi",
        registrationFees: "1200",
        status: "Inactive",
        image:Kabbadi,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "1200", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "2100 (First)/1400(Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1K2xFNAnhjUgEj2zbOrVTVvlcf545qFk1/view?usp=drive_link",
        link: "https://forms.gle/XJuThL5FCeVzdXYKA"
      },
      {
        title: "Boys Kabaddi",
        registrationFees: "1400",
        status: "Inactive",
        image: Kabbadi,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "1400", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "2500(First)/1500(Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1K2xFNAnhjUgEj2zbOrVTVvlcf545qFk1/view?usp=drive_link",
        link: "https://forms.gle/79sLWmCLsAMYD7hz9"
      },
      {
        title: "Volleyball Boys",
        registrationFees: "1500",
        status: "Inactive",
        image: Volleyball,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "1500", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "21000",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1pv6yU22vazu1LWjyJdmxF3F9CSkGO-dj/view?usp=drive_link",
        link: "https://forms.gle/1AcydsY1XKioYdX49"
      },
       {
        title: "Volleyball Girls",
        registrationFees: "1200",
        status: "Inactive",
        image: Volleyball,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "1200", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "21000",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1pv6yU22vazu1LWjyJdmxF3F9CSkGO-dj/view?usp=drive_link",
        link: "https://forms.gle/mTx1ykDH6vfXSGHq5"
      },
      
      {
        title: "Football (Boys Only)",
        registrationFees: "2500",
        status: "Inactive",
        image: Football,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "2500", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "10000 (First), 4800 (Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1asGF846TICx4gep2PKXnZoorWldwNJ6M/view?usp=drive_link",
        link: "https://forms.gle/io84B5XVfsr79zJN9"
      },
      {
        title: "Futsal (Boys Only)",
        registrationFees: "1400",
        status: "Inactive",
        image: Futsal,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "1400", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "5800 (First), 2800 (Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1JNGolcwyv9Nl5iyXEaBZEYDuYIOmsLzl/view?usp=drive_link",
        link: "https://forms.gle/w38VPpCfSVcx1f2L7"
      },
      {
        title: "Tennis Singles",
        registrationFees: "150",
        status: "Inactive",
        image: Tennis,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "150", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "1100 (First), 700 (Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/158g-A6uqIk56J9yKOzJcWx5jlGiHHoXh/view?usp=drive_link",
        link: "https://forms.gle/YYRt1Kkhr7hpzQPt5"
      },
      {
        title: "Tennis Doubles",
        registrationFees: "300",
        status: "Inactive",
        image:Tennis,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "300", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "2100 (First), 1100 (Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/158g-A6uqIk56J9yKOzJcWx5jlGiHHoXh/view?usp=drive_link",
        link: "https://forms.gle/YYRt1Kkhr7hpzQPt5"
      },
      {
        title: "Tennis Mixed Doubles",
        registrationFees: "300",
        status: "Inactive",
        image: Tennis,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "15",
        fees: { perTeam: "300", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "2100 (First), 1100 (Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/158g-A6uqIk56J9yKOzJcWx5jlGiHHoXh/view?usp=drive_link",
        link: "https://forms.gle/YYRt1Kkhr7hpzQPt5"
      },
         {
        title: "Chess",
        registrationFees: "500 for team event",
        status: "Inactive",
        image: Chess,
        tagline: '',
        description: "",
        teamSize: "4",
        minteamSize: "4",
        maxteamSize: "4",
        fees: { perTeam: "500", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1qbM_fxfCM4-4ZrURzmlCCP_5NnV38-I-/view?usp=drive_link",
        link: "https://forms.gle/huBpo9rx1c5HPXnW6"
      },
      {
        title: "Chess (Rapid)",
        registrationFees: "Rapid:100/-",
        status: "Inactive",
        image: Chess,
        tagline: '',
        description: "",
        teamSize: "1",
        minteamSize: "1",
        maxteamSize: "1",
        fees: { perTeam: "", perPerson: "100", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1qbM_fxfCM4-4ZrURzmlCCP_5NnV38-I-/view?usp=drive_link",
        link: "https://forms.gle/huBpo9rx1c5HPXnW6"
      },
      {
        title: "Chess (Blitz)",
        registrationFees: "Blitz :100/-",
        status: "Inactive",
        image: Chess,
        tagline: '',
        description: "",
        teamSize: "1",
        minteamSize: "1",
        maxteamSize: "1",
        fees: { perTeam: "", perPerson: "100", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1qbM_fxfCM4-4ZrURzmlCCP_5NnV38-I-/view?usp=drive_link",
        link: "https://forms.gle/huBpo9rx1c5HPXnW6"
      },
      {
        title: "Chess (Bullet)",
        registrationFees: "Bullet:100/-",
        status: "Inactive",
        image: Chess,
        tagline: '',
        description: "",
        teamSize: "1",
        minteamSize: "1",
        maxteamSize: "1",
        fees: { perTeam: "", perPerson: "100", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1qbM_fxfCM4-4ZrURzmlCCP_5NnV38-I-/view?usp=drive_link",
        link: "https://forms.gle/huBpo9rx1c5HPXnW6"
      },
      {
        title: "Badminton Singles",
        registrationFees: "225",
        status: "Inactive",
        image: Badminton,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "2",
        fees: { perTeam: "225", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "2500 (First), 1500 (Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/14pfPj5HvuLY8K0xwk2MxkFiyWcNAGfc_/view?usp=drive_link",
        link: "https://forms.gle/faiw5TrK1WqQ9sEQ7"
      },
      {
        title: "Badminton Doubles",
        registrationFees: "450",
        status: "Inactive",
        image: Badminton,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "4",
        fees: { perTeam: "450", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "4100 (First), 2100 (Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/14pfPj5HvuLY8K0xwk2MxkFiyWcNAGfc_/view?usp=drive_link",
        link: "https://forms.gle/faiw5TrK1WqQ9sEQ7"
      },
      {
        title: "Badminton Mixed Doubles",
        registrationFees: "450",
        status: "Inactive",
        image:Badminton,
        tagline: '',
        description: "",
        teamSize: "",
        minteamSize: "1",
        maxteamSize: "8",
        fees: { perTeam: "450", perPerson: "", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "4100 (First), 2100 (Second)",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/14pfPj5HvuLY8K0xwk2MxkFiyWcNAGfc_/view?usp=drive_link",
        link: "https://forms.gle/faiw5TrK1WqQ9sEQ7"
      },


    ],
    'Chronos': [
      {
        title: "Chronos",
        registrationFees: "500 per person",
        status: "Inactive",
        image: Chronos,
        tagline: 'Mr. & Ms. Pyrexia',
        description: "",
        teamSize: "",
        minteamSize: "",
        maxteamSize: "",
        fees: { perTeam: "", perPerson: "500", singleBoy: "", singleGirl: "", Couple: "", Solo: "", Duet: "", perhead: "" },
        contact1Name: '',
        contact1: '',
        contact2Name: '',
        contact2: '',
        contact3Name: '',
        contact3: '',
        prize: "",
        date: '',
        venue: '',
        time: '',
        rulebook: "https://drive.google.com/file/d/1S0f7WbgqVc1WfvfiExgPx5_q6rt5IMyg/view?usp=sharing",
        link: "https://forms.gle/uA2rkXa5rhg5cQqr7"
      },
    ]

  };

  useEffect(() => {
    if (eventName && events[eventName]) {
        setActiveEvent(eventName); // Set the active event based on the URL parameter
    } 
    else if(navevent){
      setActiveEvent("All Events");

    }
}, [eventName]);

  const allSubEvents = Object.keys(events).flatMap(event => events[event]);

  const handleEventClick = (event) => {
    setActiveEvent(event);
    setIsOpen(false);
    navigate(`/events/${event}`); 
  };

  // Function to navigate to sub-event details page
  const goToSubEventDetails = (subEvent) => {
    navigate('/subevent-details', { state: { subEvent, activeEvent } }); // Using navigate to handle page navigation
  };

  const eventBackgroundColors = {
    "Alfresco": "bg-gradient-to-br from-lime-300 via-lime-100 to-white",
    "Chorea": "bg-gradient-to-br from-red-400 via-red-200 to-white",
    "Chorons": "bg-gradient-to-br from-green-400 via-green-200 to-white",
    "Kalakriti": "bg-gradient-to-br from-fuchsia-400 via-fuchsia-200 to-white",
    "Littmania": "bg-gradient-to-br from-violet-400 via-violet-200 to-white",
    "Velocity": "bg-gradient-to-br from-indigo-400 via-indigo-200 to-white",
    "Sinfonia": "bg-gradient-to-br from-rose-400 via-rose-200 to-white",
    "Thespians": "bg-gradient-to-br from-emerald-400 via-emerald-200 to-white",
    "Thunderbolt": "bg-gradient-to-br from-amber-400 via-amber-200 to-white",
    "All Events": "bg-gradient-to-br from-blue-400 via-blue-200 to-white",
  };

  const getBackgroundStyle = () => {
    return eventBackgroundColors[activeEvent] || "bg-gradient-to-br from-gray-400 via-gray-200 to-white";
  };
  return (
    <div
      className="relative bg-cover bg-center bg-fixed font-sans-serif poppins min-h-screen mt-16"
      style={{
        backgroundImage: `url(${FixedBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      {/* Sidebar for event categories */}
      <div
        className={`fixed top-0 mt-16 left-0 h-full bg-[#001f3f] text-white transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-64'} overflow-x-hidden`}
        style={{ width: '16rem' }} // Width of the sidebar when open
      >
        <button
          className="absolute top-0 mt-2 right-4 bg-[#001f3f] text-3xl text-white z-30 w-16 h-16 flex items-end justify-center hover:font-bold"
          onClick={() => setIsOpen(false)}
        >
          &times;
        </button>

        {/* Invisible overlay around close button */}
        <div
          className={`absolute top-0 right-0 ${isOpen ? 'block' : 'hidden'} w-20 h-20`}
          style={{ zIndex: 20 }}
          onClick={() => setIsOpen(false)}
        ></div>

        <div className="mt-16 flex flex-col space-y-4 px-8">
          <button
            onClick={() => handleEventClick("All Events")}
            className="text-lg hover:text-white text-left hover:font-bold "
          >
            All
          </button>
          {Object.keys(events).map(event => (
            <button
              key={event}
              onClick={() => handleEventClick(event)}
              className="text-lg hover:text-grey text-left hover:font-bold"
            >
              {event}
            </button>
          ))}
        </div>
      </div>

      {/* Main content area */}
      <div
        className={`transition-transform duration-300 ease-in-out p-4 ${isOpen ? 'ml-64' : 'ml-0'}`}
      >
        {/* Toggle button */}
        <button
          className={`text-white px-2 hover:font-bold rounded fixed  left-0 z-40 w-12 h-12 flex items-end justify-start ${isOpen ?  'ml-64' : 'ml-0'}`}
          onClick={() => setIsOpen(true)}
        >
          <p className={`text-xl bg-[#001f3f] px-2 py-1 rounded fixed ${isOpen ?  'opacity-0' : 'opacity-100' } `}>☰</p>
        </button>

        {/* Invisible overlay for click area */}
        <div
          className={`absolute top-0 left-0 ${isOpen ? 'ml-64' : 'ml-0' } w-28 h-28`}
          style={{ zIndex: 20 }}
          onClick={() => setIsOpen(true)}
        ></div>

        <h2 className="mt-6 mb-16 text-4xl md:text-6xl flex justify-center text-white font-semibold">
          {activeEvent === "All Events" ? "All Events" : activeEvent}
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:gap-16 md:gap-12 gap-8 mt-4">
          {(activeEvent === "All Events" ? allSubEvents : events[activeEvent]).map((subEvent, index) => (
            <div
              key={index}
              className={`rounded-lg shadow-lg p-4 ${getBackgroundStyle(index)} hover:bg-gradient-to-b transition-all duration-500`}
              onClick={() => goToSubEventDetails(subEvent)}
            >
              <div className="p-6 text-lg md:text-xl">
                <h3 className="text-xl md:text-2xl font-semibold mb-8">{subEvent.title}</h3>
                {subEvent.registrationFees && (
                  <p className="mt-4 text-xl md:text-2xl mb-2">Fees: {subEvent.registrationFees}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventPage;
