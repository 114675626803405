import React from 'react';

const RefundPolicy = () => {
    return (
        <div className="pb-10 min-h-screen">
            <p className="leading-8 my-6 text-white">
                No refund will be issued for events, competitions, merchandise, or accommodation. Responsibility for attending the competitions, events, and collection of merchandise lies solely on the candidates who paid for them.
            </p>
        </div>
    );
}

export default RefundPolicy;
